import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import MainLayout from '../components/MainLayout';
import * as Api from '../apis';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import Chart from 'react-apexcharts';
import { navigate } from '@reach/router';
import NewRelationDialog from '../components/NewRelationDialog';
import { getUrlParams } from '../helpers/location';

const defaultChartState = {
  series: [
    {
      name: 'Point',
      // data: ['ออกกำลังกาย', 'อารมณ์', 'Wealth', 'Health', 'โภชนา'],
      data: [0, 0, 0, 0, 0],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: 'radar',
      toolbar: {
        show: false,
      },
    },
    // title: {
    //   text: 'Holistic Factor'
    // },
    xaxis: {
      categories: ['', '', '', '', ''],
      labels: {
        show: true,
        style: {
          colors: ['#4677a8', '#4677a8', '#4677a8', '#4677a8', '#4677a8'],
          fontSize: '12px',
          fontFamily: 'Sukhumvit',
          fontWeight: 'bold',
        },
      },
    },
    yaxis: {
      show: false,
      max: 100,
      min: 0,
      tickAmount: 5,
    },
  },
};

const defaultChartButtonStyle = {
  position: 'absolute',
  cursor: 'pointer',
  fontWeight: 'bold',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
};

const useStyles = makeStyles(theme => ({
  border: {
    border: '1px solid #6ccaca',
    borderRadius: '10px',
  },
  profileContainer: {},
  profileText: {
    marginTop: 5,
    marginBottom: 15,
  },
  fullnameContainer: {
    padding: 10,
    display: 'flex',
    marginBottom: 5,
  },
  avatar: {
    width: '100%',
    // height: 150,
    [theme.breakpoints.down('sm')]: {
      // height: 100,
    },
  },
  relationContainer: {
    background: '#ffeef1',
    padding: 10,
    display: 'flex',
    flexWrap: 'wrap',
  },
  relationBox: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    width: 70,
    textAlign: 'center',
  },
  suggestionContainer: {
    background: '#ffeef1',
    padding: 10,
    display: 'flex',
    flexWrap: 'wrap',
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0px 10px',
  },
  menuText: {
    fontSize: 12,
    fontWeight: 'bold',
    whiteSpace: 'pre',
    textAlign: 'center',
  },
  menuIcon: {
    width: 40,
    height: 40,
  },
  header: {
    fontSize: 24,
    margin: 0,
    fontWeight: 'bold',
    color: 'red',
    paddingTop: 20,
    textAlign: 'center',
  },
  suggestHeader: {
    marginTop: 0,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  chartContainer: {
    position: 'relative',
  },
  exerciseButton: {
    textAlign: 'center',
    cursor: 'pointer',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: -15,
  },
  nutritionButton: {
    ...defaultChartButtonStyle,
    top: 90,
    left: 0,
  },
  emotionButton: {
    ...defaultChartButtonStyle,
    top: 90,
    right: 0,
  },
  healthButton: {
    ...defaultChartButtonStyle,
    left: 30,
    bottom: 40,
  },
  wealthButton: {
    ...defaultChartButtonStyle,
    right: 45,
    bottom: 40,
  },
  tapScreenIcon: {
    width: '40px',
    marginRight: '5px',
    marginBottom: '-10px',
  },
  customTooltip: {
    whiteSpace: 'pre',
    fontSize: 14,
  },
  red: {
    color: 'red',
  },
  holisticContainer: {
    background: '#f7f7f7',
    textAlign: 'center',
  },
  holisticImage: {
    width: 250,
    height: 250,
    marginTop: 30,
  },
  redButton: {
    width: 100,
    fontSize: '16px',
    background: 'red',
    color: 'white',
    margin: '20px 0px',
    '&:hover': {
      background: 'red',
    },
  },
  detailText: {
    color: 'gray',
    fontWeight: 'bold',
  },
}));

const HolisticPage = ({ location }) => {
  const classes = useStyles();
  const [relations, setRelations] = useState([]);
  const [self, setSelf] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [chartState, setChartState] = useState(defaultChartState);
  const [holisticData, setHolisticData] = useState([]);
  const [showPreview, setShowPreview] = useState(true);
  const user = useSelector(state => state.session.user);
  const query = getUrlParams(location.search);
  const { user_question_id = '' } = query;

  function onSavedRelation() {
    fetchUserRelation();
    setOpen(false);
    acceptHolistic();
  }

  function fetchUserRelation() {
    Api.getUserRelations().then(data => {
      const _self = data.results.filter(
        relation => relation.relation === 'self',
      )[0];
      const _relations = data.results.filter(
        relation => relation.relation !== 'self',
      );
      setSelf(_self);
      setRelations(_relations);
    });
  }

  function acceptHolistic() {
    Api.updateAcceptHolistic().then(() => {
      setShowPreview(false);
    });
  }

  function onStart() {
    if (!self) {
      setOpen(true);
    } else {
      acceptHolistic();
    }
  }

  useEffect(() => {
    if (user) {
      Api.postCountData('holistic', user_question_id);
      setLoading(true);
      fetchUserRelation();
      Api.getHolisticScore().then(results => {
        const data = results.data;
        setHolisticData(data);
        setChartState({
          ...defaultChartState,
          series: [
            {
              name: 'Point',
              data,
            },
          ],
        });
        setLoading(false);
      });
      setShowPreview(!user.accept_holistic);
    }
  }, [user]);

  if (!user || loading) {
    return false;
  }

  if (showPreview || !self) {
    document.body.style.backgroundColor = '#f7f7f7';
    return (
      <div className={classes.holisticContainer}>
        <NewRelationDialog
          open={open}
          forceSelf={true}
          onSaved={onSavedRelation}
          onClose={() => setOpen(false)}
        />
        <img src='/img/menu/holistic.gif' className={classes.holisticImage} />
        <h2>Holistic Health</h2>
        <h2>การดูแลสุขภาพแบบองค์รวม</h2>
        <p className={classes.detailText}>
          คือ การดูแลสุขภาพต่างๆในชีวิต 5 ด้าน
        </p>
        <p className={classes.detailText}>อาหาร อารมณ์ ออกกำลังกาย</p>
        <p className={classes.detailText}>การป้องกันโรค และการวางแผนชีวิต</p>
        <p className={classes.detailText}>
          ไม่น่าเชื่อว่าการเห็นภาพในองค์รวมนี้
        </p>
        <p className={classes.detailText}>
          อาจจะสามารถบอกคุณภาพการใช้ชีวิตเราได้
        </p>
        <Button onClick={onStart} className={`${classes.redButton}`}>
          เริ่มเลย
        </Button>
      </div>
    );
  }

  return (
    <MainLayout>
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid item xs={12} sm={3}>
          <div className={`${classes.profileContainer}`}>
            <Grid container spacing={1}>
              <Grid item xs={4} sm={12}>
                <h3 className={classes.profileText}>PROFILE</h3>
                <img src={user.avatar} className={classes.avatar} />
              </Grid>
              <Grid item xs={8} sm={12}>
                <div
                  className={`${classes.border} ${classes.fullnameContainer}`}
                >
                  <span>
                    {self.fullname}{' '}
                    <span className={classes.red}>({self.age} ปี)</span> :{' '}
                    {self.gender_name}{' '}
                    <span className={classes.red}>{self.province_name}</span>
                  </span>
                </div>
                <div
                  className={`${classes.border} ${classes.relationContainer}`}
                >
                  {relations.map(relation => (
                    <Box className={classes.relationBox} key={relation.id}>
                      <PersonIcon style={{ color: 'red' }} />
                      <span>{relation.fullname}</span>
                    </Box>
                  ))}
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Hidden smUp>
          <Grid item xs={12} sm={4}>
            <SuggestContent />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={5}>
          <div className={classes.border} style={{ height: 420 }}>
            <p className={classes.header}>HOLISTIC FACTOR</p>
            <div
              className={classes.exerciseButton}
              onClick={() => navigate('/questionnaire/?questionnaireId=70')}
            >
              {!holisticData[0] && (
                <img
                  src='/img/menu/tap_screen.gif'
                  className={classes.tapScreenIcon}
                />
              )}
              <span>ออกกำลังกาย</span>
            </div>
            <div className={classes.chartContainer}>
              <Tooltip
                disableFocusListener
                title={'- อาหาร\n- ความสมดุล'}
                placement='bottom-start'
                classes={{ tooltip: classes.customTooltip }}
              >
                <div
                  className={classes.nutritionButton}
                  onClick={() => navigate('/questionnaire/?questionnaireId=74')}
                >
                  {!holisticData[4] && (
                    <img
                      src='/img/menu/tap_screen.gif'
                      className={classes.tapScreenIcon}
                    />
                  )}
                  <span>โภชนา</span>
                </div>
              </Tooltip>
              <Tooltip
                disableFocusListener
                title={'- สุขภาพจิต\n- ความสมดุล'}
                placement='bottom-start'
                classes={{ tooltip: classes.customTooltip }}
              >
                <div
                  className={classes.emotionButton}
                  onClick={() => navigate('/questionnaire/?questionnaireId=73')}
                >
                  {!holisticData[1] && (
                    <img
                      src='/img/menu/tap_screen.gif'
                      className={classes.tapScreenIcon}
                    />
                  )}
                  <span>อารมณ์</span>
                </div>
              </Tooltip>
              <Chart
                options={chartState.options}
                series={chartState.series}
                type='radar'
                height={350}
              />
              <Tooltip
                disableFocusListener
                title={'- การป้องกันโรค\n- สวัสดิการรัฐ'}
                placement='bottom-start'
                classes={{ tooltip: classes.customTooltip }}
              >
                <div
                  className={classes.healthButton}
                  onClick={() => navigate('/questionnaire/?questionnaireId=72')}
                >
                  {!holisticData[3] && (
                    <img
                      src='/img/menu/tap_screen.gif'
                      className={classes.tapScreenIcon}
                    />
                  )}
                  <span>Health</span>
                </div>
              </Tooltip>
              <Tooltip
                disableFocusListener
                title={'- แก่แล้วมีเงินใช้\n- ความมั่นคง'}
                placement='bottom-start'
                classes={{ tooltip: classes.customTooltip }}
              >
                <div
                  className={classes.wealthButton}
                  onClick={() => navigate('/questionnaire/?questionnaireId=71')}
                >
                  {!holisticData[2] && (
                    <img
                      src='/img/menu/tap_screen.gif'
                      className={classes.tapScreenIcon}
                    />
                  )}
                  <span>Wealth</span>
                </div>
              </Tooltip>
            </div>
          </div>
        </Grid>
        <Hidden only='xs'>
          <Grid item xs={12} sm={4}>
            <SuggestContent />
          </Grid>
        </Hidden>
      </Grid>
    </MainLayout>
  );
};

const SuggestContent = () => {
  const classes = useStyles();

  return (
    <div className={`${classes.border} ${classes.suggestionContainer}`}>
      <p className={classes.suggestHeader}>สิ่งที่เหมาะกับฉัน...</p>
      <Grid container>
        <Grid item xs={4}>
          <div
            className={classes.menuContainer}
            onClick={() => navigate('/questionnaires')}
          >
            <img
              src='/img/menu/questionnaire.png'
              className={classes.menuIcon}
            />
            <span className={classes.menuText}>แบบประเมินอื่นๆ</span>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            className={classes.menuContainer}
            onClick={() => navigate('/blogs?category_id=9')}
          >
            <img src='/img/menu/blog.png' className={classes.menuIcon} />
            <span className={classes.menuText}>อ่านสนุก</span>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            className={classes.menuContainer}
            onClick={() =>
              navigate(
                'https://eservices.nhso.go.th/eServices/mobile/login.xhtml',
              )
            }
          >
            <img
              src='/img/menu/nhso.jpg'
              className={classes.menuIcon}
              style={{ width: 60 }}
            />
            <span
              className={classes.menuText}
            >{`ตรวจสอบสิทธิ\nสวัสดิการของฉัน`}</span>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            className={classes.menuContainer}
            onClick={() => navigate('/products?category_id=9')}
          >
            <img src='/img/menu/sale.png' className={classes.menuIcon} />
            <span className={classes.menuText}>
              อะไรบ้าง..<span className={classes.red}>ลดราคา</span>
            </span>
          </div>
        </Grid>
        <Grid
          item
          xs={8}
          style={{ display: 'flex' }}
          onClick={() => navigate('/categories')}
        >
          <div className={classes.menuContainer}>
            <img src='/img/menu/consultant.png' className={classes.menuIcon} />
            <span className={classes.menuText}>ปรึกษาผู้เชี่ยวชาญ</span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HolisticPage;
